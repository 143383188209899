import { observable, action } from "mobx";
import { ApiService, ApiUrl, Enum, Constants } from "helpers";
import Alert from 'react-s-alert';
import { automationStore } from "stores";
import { AutomationType, AutomationTypeText } from "helpers/enum";

const lookupStore = observable(
  {
    currentWorkTypes: [],
    //sources: [],
    ratings: [],
    candidateStatuses: [],
    candidateNoteStatuses: [],
    candidateCustomFields: [],
    clientStatuses: [],
    clientNoteStatuses: [],
    clientCustomFields: [],
    jobSources: [],
    emailTemplateList: [],
    candidateEmailTemplateList: [],
    clientEmailTemplateList: [],
    emailCampaignLink: [],
    surveyList: [],
    textCampaignList: [],
    countries: [],
    tagSuggestions: [],
    customFields: [],
    emailLinkClickedList: [],
    smsLinkClickedList: [],
    placementStatuses: [],
    applicationStatuses: [],
    jobStatuses: [],
    candidateAttachmentCategories: [],

    resetWorkTypes() {
      this.currentWorkTypes = [];
    },

    // resetSources() {
    //   this.sources = [];
    // },

    resetRatings() {
      this.ratings = [];
    },

    resetCandidateStatuses() {
      this.candidateStatuses = [];
    },

    resetCountries() {
      this.countries = [];
    },

    resetAll() {
      this.resetWorkTypes();
      //this.resetSources();
      this.resetRatings();
      this.resetCandidateStatuses();
      this.resetCountries();
    },

    getAll() {
      this.getCurrentWorkTypes();
      //this.getSources();
      this.getRatings();
      this.getCandidateStatuses();
      this.getClientStatuses();
    },

    setCurrentWorkTypes(currentWorkTypes) {
      this.currentWorkTypes = [...currentWorkTypes];
    },

    setCustomFields(customFields) {
      this.customFields = [...customFields];
    },

    // setSources(sources) {
    //   this.sources = [...sources];
    // },

    setRatings(ratings) {
      this.ratings = [...ratings];
    },

    setCandidateStatuses(candidateStatuses) {
      this.candidateStatuses = [...candidateStatuses];
    },

    setCandidateNoteStatuses(candidateNoteStatuses) {
      this.candidateNoteStatuses = [...candidateNoteStatuses];
    },

    setCandidateCustomFields(candidateCustomFields) {
      this.candidateCustomFields = [...candidateCustomFields];
    },

    setClientStatuses(clientStatuses) {
      this.clientStatuses = [...clientStatuses];
    },

    setClientNoteStatuses(clientNoteStatuses) {
      this.clientNoteStatuses = [...clientNoteStatuses];
    },

    setClientCustomFields(clientCustomFields) {
      this.clientCustomFields = [...clientCustomFields];
    },

    setEmailTemplateList(emailTemplateList) {
      this.emailTemplateList = [];
      this.emailTemplateList = [...emailTemplateList];
    },

    setCandidateEmailTemplateList(candidateEmailTemplateList) {
      this.candidateEmailTemplateList = [];
      this.candidateEmailTemplateList = [...candidateEmailTemplateList];
    },

    setClientEmailTemplateList(clientEmailTemplateList) {
      this.clientEmailTemplateList = [];
      this.clientEmailTemplateList = [...clientEmailTemplateList];
    },

    setEmailCampaignLinks(emailCampaignLink) {
      this.emailCampaignLink = [];
      this.emailCampaignLink = [...emailCampaignLink]
    },

    setSurveyList(surveyList) {
      this.surveyList = [];
      this.surveyList = [...surveyList];
    },

    setTextCampaignList(textCampaignList) {
      this.textCampaignList = [];
      this.textCampaignList = [...textCampaignList];
    },

    setCountries(countries) {
      this.countries = [...countries];
    },

    setTagSuggestions(tagSuggestions) {
      this.tagSuggestions = [];
      this.tagSuggestions = [
        ...tagSuggestions
      ];
    },

    setJobSources(jobSources) {
      this.jobSources = [];
      this.jobSources = [
        ...jobSources
      ];
    },

    setPlacementStatuses(placementStatuses) {
      this.placementStatuses = [];
      this.placementStatuses = [
        ...placementStatuses
      ];
    },

    setApplicationStatuses(applicationStatuses) {
      this.applicationStatuses = [];
      this.applicationStatuses = [
        ...applicationStatuses
      ];
    },

    setJobStatuses(jobStatuses) {
      this.jobStatuses = [];
      this.jobStatuses = [
        ...jobStatuses
      ];
    },

    setCandidateAttachmentCategories(candidateAttachmentCategories) {
      this.candidateAttachmentCategories = candidateAttachmentCategories;
    },
    
    getBaitLink(data) {
      return (ApiService.getDetailWithParams(ApiUrl.getBaitlink, '', data).then(
        action(
          response => {
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    setEmailLinkClickedList(emailLinkClicked) {
      this.emailLinkClickedList = [];
      this.emailLinkClickedList = [...emailLinkClicked];
    },

    setSmsLinkClickedList(smsLinkClicked) {
      this.smsLinkClickedList = [];
      this.smsLinkClickedList = [...smsLinkClicked];
    },

    getCurrentWorkTypes() {
      return (ApiService.get(ApiUrl.getJAWorkTypes).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.setCurrentWorkTypes(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getCurrentWorkTypesPublic(token, type) {
      const data = {
        token,
        type
      };

      return (ApiService.getDetailWithParams(ApiUrl.getJAWorkTypesPublic, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.setCurrentWorkTypes(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    // getSources() {
    //   return (ApiService.get(ApiUrl.getJASources).then(
    //     action(
    //       response => {
    //         if (response && response.status === 200 && response.data && !response.data.isError) {
    //           // TODO HERE if something necessary, for now just return data exactly from API
    //           this.setSources(response.data.result.items);
    //         } else {
    //           Alert.error("Oops, Something went wrong! Please try again.");
    //         }
    //         return Promise.resolve(response.data);
    //       }
    //     )
    //   ).catch(
    //     action(err => {
    //       return Promise.reject(err);
    //     })
    //   ));
    // },

    getRatings() {
      return (ApiService.get(ApiUrl.getJARatings).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.setRatings(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getRatingsPublic(token, type) {
      const data = {
        token,
        type
      };

      return (ApiService.getDetailWithParams(ApiUrl.getJARatingsPublic, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.setRatings(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getCandidateCustomFields() {
      return (ApiService.get(ApiUrl.getCandidateCustomFields).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setCandidateCustomFields(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getCandidateStatuses() {
      return (ApiService.get(ApiUrl.getJACandidateStatuses).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API

              let candidateStatusData = response.data.result.items;
              if (candidateStatusData.length > 0) {//Add 'Any' into dropdown options #8372
                candidateStatusData.unshift('Any');
              }
              //modify options candidate/client status, candidate/client note status, job sources #8372
              const candidateStatusOptions = candidateStatusData.map(option => {
                let data = option === 'Any' ? { name: option, statusId: 0 } : option;
                data.isDisabled = false;

                return data
              }) || [];

              this.setCandidateStatuses(candidateStatusOptions);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getCandidateNoteStatuses() {
      return (ApiService.get(ApiUrl.getJACandidateNoteStatuses).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API§§
              //modify options candidate note status #8372
              let candidateNoteStatusData = response.data.result.items;

              if (candidateNoteStatusData.length > 0) {//Add 'Any' into dropdown options #8372
                candidateNoteStatusData.unshift('Any');
              }

              const candidateNoteStatusOptions = candidateNoteStatusData.map(option => {
                let data = option === 'Any' ? { name: option } : option;
                data.isDisabled = false;

                return data
              }) || [];

              this.setCandidateNoteStatuses(candidateNoteStatusOptions);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getClientStatuses() {
      return (ApiService.get(ApiUrl.getJAClientStatuses).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.setClientStatuses(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getClientNoteStatuses() {
      return (ApiService.get(ApiUrl.getJAClientNoteStatuses).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.setClientNoteStatuses(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getClientCustomFields() {
      return (ApiService.get(ApiUrl.getClientCustomFields).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setClientCustomFields(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getEmailCampaignLink(emailCampaignId) {
      return (ApiService.get(ApiUrl.getEmailCampaignLinks + emailCampaignId).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setEmailCampaignLinks(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    getAllEmailCampaigns(automationType = AutomationType.Candidate) {
      return (ApiService.get(ApiUrl.getAllEmailCampaign + automationType).then(
        action(
          response => {
            this.setEmailTemplateList(response.data.result);
            if (automationType === AutomationType.Candidate) {
              this.setCandidateEmailTemplateList(response.data.result.filter(x => x.category && x.category.toLowerCase().includes(AutomationTypeText.Candidate.toLowerCase())));
            } else if (automationType === AutomationType.Client) {
              this.setClientEmailTemplateList(response.data.result.filter(x => x.category && x.category.toLowerCase().includes(AutomationTypeText.Contact.toLowerCase())));
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    getAllSurveys() {
      return (ApiService.get(ApiUrl.getAllSurveys).then(
        action(
          response => {
            this.setSurveyList(response.data.result)
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    getTextCampaignList(type) {
      return (ApiService.getDetail(ApiUrl.getCampaignByType, type).then(
        action(
          response => {
            this.setTextCampaignList(response.data.result ?? [])
            return Promise.resolve(response.data.result);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getShortlinkByToken(contactType, token) {
      const apiUrlByType = contactType === Enum.ContactType.Candidate ? ApiUrl.getShortlinkByToken : ApiUrl.getShortlinkClientByToken;

      return (ApiService.getDetail(apiUrlByType, token).then(
        action(
          response => {

            const pattern = /^((http|https|ftp):\/\/)/;
            const actionPattern = /^((mailto|sms|tel):)/;
            let originalUrl = response.data.result.originalUrl

            // check if its an valid URL
            if (!pattern.test(originalUrl) && !actionPattern.test(originalUrl)) {
              originalUrl = "http://" + originalUrl;
            }
            if (pattern.test(originalUrl)) {
              const url = new URL(originalUrl)
              // add parameter to url
              url.searchParams.append('rid', response.data.result.token);
              url.searchParams.append('ridct', contactType);
              return Promise.resolve(url.href);
            }

            return Promise.resolve(originalUrl);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getCountries() {
      return (ApiService.get(ApiUrl.getJACountries).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setCountries(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getCountriesPublic(token, type) {
      const data = {
        token,
        type
      };

      return (ApiService.getDetailWithParams(ApiUrl.getJACountriesPublic, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setCountries(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getTagSuggestionsWithParams(grid, type = null) {
      return (ApiService.getDetailWithParams(ApiUrl.getTagSuggestions, grid, { type }).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setTagSuggestions(response.data.result.map((item) => {
                return {
                  label: item,
                  value: item
                }
              }));
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getTagSuggestions(type = null) {
      return (ApiService.getDetail(ApiUrl.getTagSuggestions, type).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setTagSuggestions(response.data.result.map((item) => {
                return {
                  label: item,
                  value: item
                }
              }));
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getJobSources() {
      return (ApiService.get(ApiUrl.getJobApplicationSources).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.setJobSources(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getCustomFieldsPublic(token, type) {
      const data = {
        token,
        type
      };

      return (ApiService.getDetailWithParams(ApiUrl.getCustomFieldsPublic, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setCustomFields(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getMultipleTriggerStatus(automationType) { // [NOTE] Change to multiple request to handle on stop the Promises before all resolve when each one is resolve
      const getActivityLogName = automationType === AutomationType.Candidate ? { url: ApiUrl.getActivityLogName } : { url: ApiUrl.getClientActivityLogName }
      const getNoteStatuses = automationType === AutomationType.Candidate ? { url: ApiUrl.getJACandidateNoteStatuses } : { url: ApiUrl.getJAClientNoteStatuses }
      const getStatuses = automationType === AutomationType.Candidate ? { url: ApiUrl.getJACandidateStatuses } : { url: ApiUrl.getJAClientStatuses }
      let param = [
        getStatuses,
        getNoteStatuses,
        { url: ApiUrl.getJobApplicationSources },
        getActivityLogName, // get link clicked title
        { url: ApiUrl.getPlacementStatuses },
        { url: ApiUrl.getApplicationStatuses },
        { url: ApiUrl.getJobStatuses },
        { url: ApiUrl.getJACountries }
      ];
      return (ApiService.multipleRequestAllSettled(param, '', Constants.ALERT_SOMETHING_WENT_WRONG).then(
        action(
          response => {
            let errorMessage = [];
            response.filter(x => x.status === "rejected").forEach((item) => {
              if (item && item.reason && item.reason.response && item.reason.response.data) {
                errorMessage = [...errorMessage, item.reason.response.data];
              }
            });

            if (errorMessage.length > 0) {
              errorMessage.forEach(item => {
                Alert.error(item);
              });
            }

            //modify options candidate/client status, candidate/client note status, job sources #8372
            let candidateOrClientStatusData = response[0].value?.data?.result?.items;
            let candidateOrClientNoteStatusData = response[1].value?.data?.result?.items;
            let jobSourceData = response[2].value?.data?.result;
            let placementStatusData = response[4].value?.data?.result;
            let applicationStatusData = response[5].value?.data?.result;
            let jobStatusData = response[6].value?.data?.result;

            // console.log('country:', toJS(response[7].value?.data?.result));
            let countriesData = response[7].value?.data?.result?.items;

            if (candidateOrClientStatusData.length > 0) {//Add 'Any' into dropdown options #8372
              candidateOrClientStatusData.unshift({ statusId: 0, name: 'Any' });
            }
            if (candidateOrClientNoteStatusData.length > 0) {//Add 'Any' into dropdown options #8372
              candidateOrClientNoteStatusData.unshift({ name: 'Any' });
            }
            if (jobSourceData.length > 0) {//Add 'Any' into dropdown options #8372
              jobSourceData.unshift('Any');
            }
            if (placementStatusData.length > 0) {//Add 'Any' into dropdown options #8372
              placementStatusData.unshift({ statusId: 0, name: 'Any' });
            }
            if (applicationStatusData.length > 0) {
              //#8949 Add 'Any' into dropdown options
              applicationStatusData.unshift({ statusId: 0, name: 'Any' });
            }
            if (jobStatusData.length > 0) {
              // #8949 Add 'Any' into dropdown options
              jobStatusData.unshift({ statusId: 0, name: 'Any' });
            }

            if (countriesData.length > 0) {
              countriesData.unshift({ name: 'Any' });
            }

            //modify options candidate/client status, candidate/client note status, job sources #8372
            const candidateOrClientStatusOptions = candidateOrClientStatusData.map(option => {
              option.isDisabled = false;
              return option;
            }) || [];

            const candidateOrClientNoteStatusOptions = candidateOrClientNoteStatusData.map(option => {
              option.isDisabled = false;
              return option;
            }) || [];

            const placementStatusOption = placementStatusData.map(option => {
              option.isDisabled = false;
              return option;
            }) || [];

            const jobSourceOptions = jobSourceData.map(option => {
              let optionData = {};
              optionData.label = option;
              optionData.isDisabled = false;
              return optionData;
            })

            const applicationStatusOptions = applicationStatusData.map(option => {
              option.isDisabled = false;
              return option;
            }) || [];

            const jobStatusOptions = jobStatusData.map(option => {
              option.isDisabled = false;
              return option;
            }) || [];

            automationType === AutomationType.Candidate ?
              this.setCandidateStatuses(candidateOrClientStatusOptions || []) :
              this.setClientStatuses(candidateOrClientStatusOptions || []);
            automationType === AutomationType.Candidate ?
              this.setCandidateNoteStatuses(candidateOrClientNoteStatusOptions || []) :
              this.setClientNoteStatuses(candidateOrClientNoteStatusOptions || []);
            this.setJobSources(jobSourceOptions || []);
            this.setEmailLinkClickedList(response[3].value?.data?.result?.filter(x => x.activityLogType === 17) || []);
            this.setSmsLinkClickedList(response[3].value?.data?.result?.filter(x => x.activityLogType === 26) || []);
            this.setPlacementStatuses(placementStatusOption || []);
            this.setApplicationStatuses(applicationStatusOptions || []);
            this.setJobStatuses(jobStatusOptions || []);
            this.setCountries(countriesData || []);
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getActivityLogName() {
      const { automationType } = automationStore;
      const getActivityLogName = automationType === AutomationType.Candidate ? ApiUrl.getActivityLogName : ApiUrl.getClientActivityLogName
      return (ApiService.get(getActivityLogName).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setEmailLinkClickedList(response.data.result?.filter(x => x.activityLogType === 17) || []);
              this.setSmsLinkClickedList(response.data.result?.filter(x => x.activityLogType === 26) || []);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getApplicationStatusesList() {
      return (ApiService.get(ApiUrl.getApplicationStatuses).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const applicationStatusData = response.data.result;
              this.setApplicationStatuses(applicationStatusData || []);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getCandidateAttachmentCategories() {
      return (ApiService.get(ApiUrl.getCandidateAttachmentCategories).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const attachmentCategories = response.data.result;
              this.setCandidateAttachmentCategories(attachmentCategories || []);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    }
  }
);

export default lookupStore;
